<template>
	<screen class="donor-list-view" v-if="donor" size="large">
		<h1 slot="title">
			Donation History: {{ donor.first_name }} {{ donor.last_name }}
		</h1>
		<div slot="chart">
			<chart v-if="graph" :data="graph" prepend="$"></chart>
		</div>
		<el-table slot="table" :data="graph" style="width: 100%">
			<el-table-column prop="date" label="Date"> </el-table-column>
			<el-table-column prop="value" label="Amount">
				<template slot-scope="scope">
					{{ numberFormat(scope.row.value) }}
				</template>
			</el-table-column>
		</el-table>
	</screen>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { get, numberFormat } from '@/utilities'
import table from '@/mixins/table'

import UiButton from '../../ui/Button'
import Chart from '../../ui/Chart'
import Screen from '../../ui/Screen'

export default {
	name: 'donor-detail-view',
	components: { UiButton, Screen, Chart },
	mixins: [table],
	props: ['donorId'],
	computed: {
		...mapState({
			donor: ({ donorDetail }) => donorDetail.item,
			graph: ({ donorDetail }) => donorDetail.graph
		})
	},
	head: {
		title: {
			inner: 'Donor'
		}
	},
	methods: {
		...mapActions(['getDonor']),
		paramsChanged(params) {
			this.getDonors(params)
		},
		get,
		numberFormat
	},
	mounted() {
		this.getDonor(this.donorId)
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';
</style>
